$(document).ready(function () {

    $('.side-meniu-trigger').click(function () {
        var target = $('.side-meniu');
        var targetTrigger = $('.side-meniu-trigger');
        target.toggleClass('out');
        targetTrigger.toggleClass('forcewhite');
    });

});

$(function () {
    $('.curtains').curtain({
        scrollSpeed: 400
    });
});

$(document).scroll(function() {
    var win = $(window);
    var nav = $('.side-meniu-trigger');
    var scroll = $(this).scrollTop();
    var section1 = $('.first-section').height() - 49;
    var section2 = $('.second-section').height();
    var nextblack = $('.next-black').height();
    var section12 = section1 + section2;
    var sectionnextblack = section1 + nextblack;

    if (scroll >= section1 && scroll < section12) {
        nav.addClass("dark");
    } else {
        nav.removeClass("dark");
    }

    if ($('.second-section-apie').hasClass('next-black')) {
        if (scroll >= section1) {
            nav.addClass("dark");
        } else {
            nav.removeClass("dark");
        }
    }

});